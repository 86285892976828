import { InjectionToken } from '@angular/core';
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz');

export interface UidProvider {
  generate(): string;
}

export const UID_PROVIDER = new InjectionToken<UidProvider>('Uid provider');

export class RealUidProvider implements UidProvider {
  generate(length = 21): string {
    return nanoid(length);
  }
}
